import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: en,
    hello: "Hello",
    instructions: {
      logout: "Logout",
      ok: "Ok",
      close: "Close",
      login: "Log In",
      next: "Next",
      back: "Back",
    },
    extras: {
      at: "at",
      no: "No",
      yes: "Yes",
      footer: "© 2023-2024 clearfx.ai All rights reserved.",
      language: "Language",
    },
    menus: {
      home: "Home",
      apiDoc: "API Documentation",
      apiTest: "API Test in-place",
      monitoring: "Monitoring (TBR)",
      ipAllowList: "IP Allowlist (TBR)",
      session: "Session (TBR)",
      notifications: "Notifications (TBR)"
    },
    toolbar: {
      empresaTrading: "Joao Silva (demo) | EMPRESA TRADING LTDA",
      timeZone: "Time Zone: BRT",
      title: "AlgoRFQ Console",
      errorMessage: "Unable to log out. Try again later."
    },
    home: {
      title: "Connectivity",
      liquidityAlerts: "Liquidity Providers Alerts",
      lastTwentyFourHours: "Last 24 hours",
      apiSummary: "Api Summary",
      requests: "Requests",
      copiedApikey: "Copied APIKEY",
      apikey: "APIKEY",
      confirmationMessage: "Are you sure you want to regenerate the APIKey?",
      tlsSummary: {
        title: "TLS Summary",
        certificates: {
          title: "Certificates",
          addCertificate: "Add Certificate",
          active: "Active",
          inactive: "Inactive (TBR)",
          status: "Status",
          cname: "CName",
          serialNumber: "Serial Number",
          lastConnection: "Last Connection",
          certificateCreation: "Certificate Creation",
          expiration: "Expiration",
          download: "Download",
        }
      },
      donut: {
        critical: "Critical",
        warning: "Warning",
        noAlerts: "No Alerts"
      }
    },
    requestHelp: {
      requestHelp: "Request Help",
      greeting: "Hi, how can we help you?",
      instruction: "Select how you would like to receive help from our Customer Representatives",
      sendEmail: {
        title: "Send Us An Email",
        btnText: "Send Email"
      },
      helpTicket: {
        title: "Submit A Help Ticket",
        description: "We will respond as soon as possible",
        btnText: "Start Ticket (TBR)"
      },
      liveChat: {
        title: "Live Chat",
        btnText: "Chat Now (TBR)"
      }
    },
    landing: {
      title: "AlgoRFQ Console",
      description: {
        descriptionOne: "Log in to monitor, configure and alert on your connected applications to",
        descriptionTwo: "clearfx.ai.",
      },
      algoRfqConsole: {
        title: "clearfx.ai | AlgoRFQ Console",
        description: {
          descriptionOne: "Consolidate your enterprise in a centralized interface",
          descriptionTwo: "that helps you manage, monitor and alert on FX workflows.",
        }
      },
      simplifyDevelopment: {
        title: "Simplify Development",
        description: {
          descriptionOne: "Access API resources, documentation and developer tools to",
          descriptionTwo: "register, manage and test applications.",
        },
      },
      secureConnectivity: {
        title: "Secure Connectivity",
        description: {
          descriptionOne: "Control your connections and manage your security",
          descriptionTwo: "credentials, expirations and more.",
        }
      },
    },
    login: {
      title: "Login",
      description: {
        descriptionOne: "This is a secure clearfx.ai authentication service ",
        descriptionTwo: "that allows you access to clearfx.ai services",
        descriptionThree: "from wherever you are.",
      },
      corporateEmail: "Coporate Email",
      password: "Password",
      authenticationFail: "User authentication failed: invalid credentials"
    },
    notFound: {
      title: "404 Page not Found",
      descriptionOne: "I'm afraid you've found a page that doesn't exist on Clearfxai.",
      descriptionTwo: "That can happen when you follow a link to something that has since been deleted.",
      descriptionThree: "Or the link was incorrect to begin with.",
      apologies: "Sorry about that.",
    },
  },
  pt: {
    $vuetify: pt,
    hello: "Olá",
    instructions: {
      logout: "Sair",
      ok: "Ok",
      close: "Fechar",
      login: "Entrar",
      next: "Continuar",
      back: "Voltar",
    },
    extras: {
      at: "às",
      no: "Não",
      yes: "Sim",
      footer: "© 2023-2024 clearfx.ai Todos os direitos reservados.",
      language: "Idioma",
    },
    menus: {
      home: "Início",
      apiDoc: "Documentação da API",
      apiTest: "Teste local da API",
      monitoring: "Monitoramento (TBR)",
      ipAllowList: "Lista de Permissões de IP (TBR)",
      session: "Sessão (TBR)",
      notifications: "Notificações (TBR)"
    },
    toolbar: {
      empresaTrading: "Joao Silva (demo) | EMPRESA TRADING LTDA",
      timeZone: "Fuso Horário: BRT",
      title: "Console AlgoRFQ",
      errorMessage: "Não foi possível sair. Tente novamente mais tarde."
    },
    home: {
      title: "Conectividade",
      liquidityAlerts: "Alertas dos Provedores de Liquidez",
      lastTwentyFourHours: "Últimas 24 horas",
      apiSummary: "Resumo da API",
      requests: "Requisições",
      copiedApikey: "APIKEY copiada",
      apikey: "Chave da API",
      confirmationMessage: "Você tem certeza de que deseja regenerar a APIKey?",
      tlsSummary: {
        title: "Resumo TLS",
        certificates: {
          title: "Certificados",
          addCertificate: "Adicionar Certificado",
          active: "Ativo",
          inactive: "Inativo (TBR)",
          status: "Status",
          cname: "CName",
          serialNumber: "Número de Série",
          lastConnection: "Última Conexão",
          certificateCreation: "Criação do Certificado",
          expiration: "Expiração",
          download: "Baixar",
        }
      },
      donut: {
        critical: "Crítico",
        warning: "Atenção",
        noAlerts: "Sem alertas"
      }
    },
    requestHelp: {
      requestHelp: "Pedir Ajuda",
      greeting: "Oi, como podemos ajudar você?",
      instruction: "Selecione como você gostaria de receber ajuda de nossos Representantes",
      sendEmail: {
        title: "Envie um Email",
        btnText: "Enviar Email"
      },
      helpTicket: {
        title: "Envie um Ticket de Ajuda",
        description: "Responderemos o mais rápido possível",
        btnText: "Iniciar Ticket (TBR)"
      },
      liveChat: {
        title: "Chat",
        btnText: "Conversar Agora (TBR)"
      }
    },
    landing: {
      title: "Console AlgoRFQ",
      description: {
        descriptionOne: "Faça login para monitorar, configurar e alertar suas aplicações conectadas a",
        descriptionTwo: "clearfx.ai.",
      },
      algoRfqConsole: {
        title: "clearfx.ai | Console AlgoRFQ",
        description: {
          descriptionOne: "Consolide sua empresa em uma interface centralizada",
          descriptionTwo: "que ajuda a gerenciar, monitorar e alertar sobre fluxos de trabalho FX.",
        }
      },
      simplifyDevelopment: {
        title: "Simplifique o Desenvolvimento",
        description: {
          descriptionOne: "Acesse recursos de API, documentação e ferramentas de desenvolvedor para",
          descriptionTwo: "registrar, gerenciar e testar aplicações.",
        },
      },
      secureConnectivity: {
        title: "Conectividade Segura",
        description: {
          descriptionOne: "Controle suas conexões e gerencie suas credenciais de segurança, ",
          descriptionTwo: "expirações e mais.",
        }
      },
    },
    login: {
      title: "Entrar",
      description: {
        descriptionOne: "Este é um serviço de autenticação seguro da clearfx.ai",
        descriptionTwo: "que permite acesso aos serviços clearfx.ai",
        descriptionThree: "de onde quer que você esteja.",
      },
      corporateEmail: "Email Corporativo",
      password: "Senha",
      authenticationFail: "Falha na autenticação do usuário: credenciais inválidas"
    },
    notFound: {
      title: "404 Página não Encontrada",
      descriptionOne: "Receio que você tenha encontrado uma página que não existe na Clearfxai.",
      descriptionTwo: "Isso pode acontecer quando você segue um link para algo que já foi excluído.",
      descriptionThree: "Ou o link estava incorreto.",
      apologies: "Desculpe por isso.",
    },
  }
};

const initialLocale = localStorage.getItem('userLanguage') || 'en';

const i18n = new VueI18n({
  locale: initialLocale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
