<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8"
      >
        <div class="d-flex flex-column">
          <div class="d-flex mb-16 pb-8">
            <div class="text-h3 font-weight-light">
              {{ $t('home.title') }}
            </div>
            <v-spacer></v-spacer>
            <div class="font-weight-light">
              {{ now }}
            </div>
          </div>

          <v-row no-gutters>
            <v-col 
              xl="6"
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="d-flex flex-column align-center"
            >
              <div class="d-flex flex-column">
                <div class="text-h5 font-weight-light">
                  {{ $t('home.liquidityAlerts').toUpperCase() }}
                </div>
                <div class="text--disabled font-weight-light">
                  {{ $t('home.lastTwentyFourHours') }}
                </div>
              </div>
              <donut-chart></donut-chart>
            </v-col>

            <v-col 
              xl="6"
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="d-flex"
            >
              <v-divider vertical inset class="py-2" v-if="isXLarge || isLarge"></v-divider>

              <div class="d-flex flex-column mx-auto" style="max-width: 480px; height: 100%;">
                <div class="d-flex flex-column mb-8">
                  <div class="text-h5 font-weight-light">
                    {{ $t('home.apiSummary').toUpperCase() }}
                  </div>
                  <div class="text--disabled font-weight-light">
                    {{ $t('home.lastTwentyFourHours') }}
                  </div>
                </div>

                <div
                  class="my-auto d-flex flex-column fill-height"
                  style="justify-content: space-evenly;"
                >
                  <div class="text-h2 mt-0 ml-3 font-weight-light mb-4">
                    {{ requestCount }} <span class="text-subtitle-1 font-weight-light"> {{ $t('home.requests') }} </span>
                  </div>

                  <div class="text-h2 mb-0 ml-3 font-weight-light d-flex align-end" style="height: 50px; margin-top: 10px;">
                    <div class="" style="position: relative; display: flex; align-items: center;">
                      <span
                        class="text--disabled font-weight-light"
                        :style="{
                          'font-size': '12px',
                          visibility: showCopiedText ? 'visible' : 'hidden',
                          position: 'absolute',
                          left: '-90px',
                          top: '-55px'
                        }"
                      >
                        {{ $t('home.copiedApikey') }}
                      </span>
                    </div>

                    <div class="mr-3">
                      <v-btn
                        elevation="0"
                        tile
                        style="
                        max-width: 42px !important;
                        min-width: 42px !important;
                        height: 42px;
                        "
                        color="primary"
                        @click="copyApiKey()"
                      >
                        <v-icon>
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                    
                    <div class="mr-3">
                      <v-btn
                        elevation="0"
                        tile
                        style="
                        max-width: 42px !important;
                        min-width: 42px !important;
                        height: 42px;
                        "
                        color="primary"
                        @click="refreshApiKeyClicked()"
                      >
                        <v-icon>
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </div>

                    <div class="mr-3">
                      <span class="text-subtitle-1 font-weight-light"> {{ $t('home.apikey') }} 
                      <pre>{{ apiKey }}</pre>
                      </span>
                    </div>
                  </div>
                </div>
                <v-dialog
                v-model="dialogRegenAPIKey"
                max-width="515px"
                persistent
                >
                  <v-card :disabled="loadingRegenAPIKey">
                    <v-card-title>
                      {{ $t('home.confirmationMessage') }}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="dialogRegenAPIKey = false"
                      >
                        {{ $t('extras.no') }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        tile
                        @click="regenAPIKEYClicked()"
                        :loading="loadingRegenAPIKey"
                      >
                        {{ $t('extras.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>
    
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="pt-4 d-flex flex-column"
            >
              <div class="text-h5 font-weight-light mb-10">
                {{ $t('home.tlsSummary.title') }}
              </div>

              <v-card outlined>
                <v-card-title class="d-flex">
                  <span class="font-weight-regular">
                    {{ $t('home.tlsSummary.certificates.title') }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn outlined 
                    small 
                    color="white"
                    disabled
                  >
                    <v-icon
                      left
                      color="white"
                    >
                      mdi-plus
                    </v-icon>
                    {{ $t('home.tlsSummary.certificates.addCertificate') }}
                  </v-btn>
                </v-card-title>

                <v-tabs
                  v-model="certificatesTab"
                  dense
                  background-color="#1E1E1E"
                  :slider-size="5"
                >
                  <v-tab style="width: 150px;">
                    <span 
                      class="white--text"
                      style="font-weight: 400;"
                    >
                      {{ $t('home.tlsSummary.certificates.active') }}
                    </span>
                  </v-tab>

                  <v-tab style="width: 150px;" disabled>
                    <span 
                      class="white--text"
                      style="font-weight: 400;"
                    >
                    {{ $t('home.tlsSummary.certificates.inactive') }}
                    </span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="certificatesTab">
                  <v-tab-item>
                    <v-simple-table style="border-top: 4px solid #121212;">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.status') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.cname') }}
                            </th>
                            <th 
                              class="text-left"
                            >
                              <!-- style="max-width: 400px; width: 400px" -->
                              {{ $t('home.tlsSummary.certificates.serialNumber') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.certificateCreation') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.lastConnection') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.expiration') }} 
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.download') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in tlsList"
                            :key="item.entry_id"
                            style="background-color: #121212;"
                          >
                            <td
                              :class="{
                                'green--text text--accent-4': item.entry_status && item.entry_status.toLocaleLowerCase() === 'active'
                              }"
                            >
                              {{ statusTable(item.entry_status) }}
                            </td>
                            <td>{{ item.tls_cname }}</td>
                            <td>{{ item.tls_serialno }}</td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timecreate | formatDate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.certificate_creation_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timelast | formatDate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.last_connection_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timedue | formatDate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.expiration_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <v-btn
                                icon
                                class="ml-3"
                                @click="download(item)"
                              >
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item>
                    <v-simple-table style="border-top: 4px solid #121212;">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.status') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.cname') }}
                            </th>
                            <th 
                              class="text-left"
                              style="max-width: 400px; width: 400px"
                            >
                              {{ $t('home.tlsSummary.certificates.serialNumber') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.certificateCreation') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.lastConnection') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.expiration') }}
                            </th>
                            <th class="text-left">
                              {{ $t('home.tlsSummary.certificates.download') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in inactiveCertificates"
                            :key="item.entry_id"
                            style="background-color: #121212;"
                          >
                            <td
                              :class="{
                                'green--text text--accent-4': item.entry_status && item.entry_status.toLocaleLowerCase() === 'active'
                              }"
                            >
                              {{ item.entry_status }}
                            </td>
                            <td>{{ item.tls_cname }}</td>
                            <td>{{ item.tls_serialno }}</td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timecreate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.certificate_creation_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timelast }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.last_connection_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timedue }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.expiration_date }}
                                </span> -->
                              </div>
                            </td>
                            <td> 
                              <v-btn
                                icon
                                class="ml-3"
                                @click="download(item)"
                              >
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DonutChart from "./../components/DonutChart.vue"
import { useDisplayStore, useAuthStore, useTlsStore, useConsoleApiStore, APIKEY } from "./../store/index"
import { mapState, mapActions } from "pinia"
import * as utils from "./../utils.js"

export default {
  components: {
    DonutChart,
  },
  filters: {
    formatDate (timestamp) {
      return utils.getLocaleFormattedDateTime(timestamp)
    }
  },
  data () {
    return {
      certificatesTab: 0,
      activeCertificates: [
        {
          entry_status: "Active",
          tls_cname: "653836:1",
          tls_serialno: "32 f3 6a 4d 33 23 3f 59 ac 97 al cd 8c 93 6e e2",
          timecreate: "Mar 05, 2024|10 Days ago",
          timelast: "Mar 14, 2024|1 Day ago",
          timedue: "Mar 05, 2026|24 Months",
          // certificate_creation_days: "10 Days ago",
          // certificate_creation_date: "Mar 05, 2024",
          // last_connection_days: "1 Day ago",
          // last_connection_date: "Mar 14, 2024",
          // expiration_months: "24 Months",
          // expiration_date: "Mar 05, 2026"
        }
      ],
      inactiveCertificates: [
        {
          entry_status: "Active",
          tls_cname: "653836:1",
          tls_serialno: "32 f3 6a 4d 33 23 3f 59 ac 97 al cd 8c 93 6e e2",
          timecreate: "Mar 05, 2024|10 Days ago",
          timelast: "Mar 14, 2024|1 Day ago",
          timedue: "Mar 05, 2026|24 Months",
          // certificate_creation_days: "10 Days ago",
          // certificate_creation_date: "Mar 05, 2024",
          // last_connection_days: "1 Day ago",
          // last_connection_date: "Mar 14, 2024",
          // expiration_months: "24 Months",
          // expiration_date: "Mar 05, 2026"
        }
      ],
      APIKEY: APIKEY,
      dialogRegenAPIKey: false,
      loadingRegenAPIKey: false,
      showCopiedText: false
    }
  },
  computed: {
    ...mapState(useDisplayStore, [
      "isXSmall",
      "isSmall",
      "isMedium",
      "isLarge",
      "isXLarge"
    ]),
    ...mapState(useTlsStore, [
      "tlsList"
    ]),
    ...mapState(useConsoleApiStore, [
      "requestCount",
      "apiKey"
    ]),
    // ...mapState(useAuthStore, [
    //   "getApiKey"
    // ]),
    // ...mapWritableState(useAuthStore, [
    //   "apiKey"
    // ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    }
  },
  mounted () {
    // const apiKey = localStorage.getItem("apiKey")
    // if (apiKey) {
    //   this.apiKey = apiKey
    //   return
    // }
    // this.getApiKeyRequest()
    // this.apiKey = APIKEY
    this.getTLSList()
    this.fetchRequestCount()
    this.fetchApiKey()
  },
  methods: {
    ...mapActions(useAuthStore, {
      regenAPIKEY: "regenAPIKEY",
      // getApiKeyRequest: "getApiKeyRequest"
    }),
    ...mapActions(useTlsStore, [
      "getTLSList",
      "downloadTLS"
    ]),
    ...mapActions(useConsoleApiStore, [
      "fetchRequestCount",
      "fetchApiKey",
      "refreshApiKey"
    ]),
    download (certificate) {
      // const blob = new Blob([JSON.stringify(certificate)], {type: "text/plain"})
      // const url = window.URL.createObjectURL(blob)
      // const a = document.createElement("a")
      // a.href = url
      // a.download = certificate.entry_id
      // a.click()
      const entryId = certificate.entry_id
      this.downloadTLS(entryId)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/zip" })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = `${entryId}.zip`
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    regenAPIKEYClicked () {
      this.refreshApiKey(this.apiKey)
        .then(() => {
          localStorage.setItem("apiKey", this.apiKey);
          this.dialogRegenAPIKey = false
        })
        .catch((error) => {
          console.error(error);
        });
    },
    refreshApiKeyClicked() {
      this.dialogRegenAPIKey = true
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey)
      this.showCopiedText = true;
      setTimeout(() => {
        this.showCopiedText = false;
      }, 4000);
    },
    statusTable (status) {
      if(status.toLowerCase() === 'active'){
        return this.$t('home.tlsSummary.certificates.active')
      }
    }
  }
}
</script>
